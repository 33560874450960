/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */


import { getItemFromSessionStorage, makeRequest } from '../../helpers';


const getLatestDeviceQuote = async (cognitoAccessTokenKey, props = {}) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  return await makeRequest({
    method: 'GET',
    url: `${basePath}/device/device_quotes/latest`,
    authToken: authToken,
    history: props.history,
  });
};

export const hasPurchasedDevicePolicy = async (cognitoAccessTokenKey, props) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return false because the call is going to fail
    return false;
  }
  return getLatestDeviceQuote(cognitoAccessTokenKey, props).then(response => {
    if (response && response.device_quote) {
      const data = formatResponse(response);
      return !!data.policy_reference;
    }
    return false;
  });
};

export const getLatestUnpurchasedDeviceQuote = (cognitoAccessTokenKey, props) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return an object that specifies no token was passed
    return { hasNoAccessToken: true };
  }
  return getLatestDeviceQuote(cognitoAccessTokenKey, props).then(response => {
    if (response && response.quote) {
      const data = formatResponse(response);
      return !data.policy_reference ? data : {};
    }
    return {};
  });
};

const getPolicyHolderDetails = ({ first_name, last_name, title, email, mobile_phone, address }) => {
  return {
    firstName: first_name,
    lastName: last_name,
    title,
    email,
    phoneNo: mobile_phone,
    addressLine1: address.address_line1,
    addressLine2: address.address_line2,
    addressLine3: address.address_line3,
    addressCounty: address.address_line4,
    addressEircode: address.eircode,
    addressTown: address.town,
  };
};

const getDeviceDetails = ({ network, imei_number }) => {
  return {
    network,
    serialNumber: imei_number,
  };
};

function formatResponse(data) {
  const { policy_holder_details, cover_details, promotion_code } = data.quote;
  const deviceDetails = cover_details ? getDeviceDetails(cover_details) : {};

  return {
    values: {
      ...getPolicyHolderDetails(policy_holder_details),
      ...deviceDetails,
      promoCode: promotion_code,
    },
  };
}
