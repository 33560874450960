/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
import './ProductLandingPage.scss';

import { AccordionCard, Button, PhoneButton, Text } from '@arachas/core/lib';
import React, { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import type { Match, RouterHistory } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import { MobileBackButton } from '../../components/MobileBackButton';
import { commonPageStylesIdentifier, iconsAndTitles, URL_PROMOCODE } from '../../constants';
import { primaryPurple } from '../../constants/colors';
import {
  CAR_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE
} from '../../constants/insuranceTypeConstants';
import {
  deviceInsurancePhoneNumber,
  homeInsurancePhoneNumber,
  motorInsurancePhoneNumber,
  travelInsurancePhoneNumber,
  vanInsuranceNumber
} from '../../constants/phoneNumbers';
import {
  saveInSessionStorage
} from '../../helpers';
import { getAccessToken } from '../../helpers';
import { isAgent } from '../../helpers/AgentCheck';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import { UserProfileContext } from '../../UserProfile';

interface Props {
  history: RouterHistory;
  location: Location;
  match: Match;
}
const expectedPreviousURL = process.env.REACT_APP_EXPECTED_PROMOCODE_WEBSITE_ORIGIN ? process.env.REACT_APP_EXPECTED_PROMOCODE_WEBSITE_ORIGIN : "";


export const ProductLandingPage = (props: Props) => {
  const className = 'c-ProductLandingPage';
  const { history, match, location } = props;
  const insuranceType = match.params.type ? match.params.type : '';
  const [hasPurchasedInsurance, setHasPurchasedInsurance] = useState(false);
  const { userProfile } = useContext(UserProfileContext);
  const { cognitoUser } = useContext(CognitoContext);
  const isLoggedIn = !!getAccessToken(cognitoUser);

  const gtmClickEvent = () => {
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnProductPage({ insuranceType }),
      dataLayerName: 'dataLayer'
    })
  }

  //*Note* if updating the below promocode journeys make sure to update the removeURLPromoCode function in sessionStorageHelper.js
  const promoCodesInURLJourneys = [CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE, HOME_INSURANCE_TYPE, VAN_INSURANCE_TYPE, TRAVEL_INSURANCE_TYPE];
  const handleSubmit = async () => {
    history.push({
      pathname: `${routes.loginPage.url}`,
      state: {
        pageType: `productPage`,
        type: `${insuranceType}`
      }
    });
  };

  useEffect(() => {
    if (userProfile && userProfile.purchasedInsuranceTypes) {
      const purchasedInsuranceTypes = userProfile.purchasedInsuranceTypes;
      setHasPurchasedInsurance(purchasedInsuranceTypes[insuranceType]);
    }

    if (promoCodesInURLJourneys.includes(insuranceType)) {
      checkURLParameters();
    }

  }, [userProfile]);

  const checkURLParameters = () => {
    const promocodeFromURL = new URLSearchParams(location.search).get("promocode");
    var previousUrl = document.referrer;
    if (promocodeFromURL && previousUrl.includes(expectedPreviousURL)) {
      saveInSessionStorage(`${insuranceType}${URL_PROMOCODE}`, JSON.stringify(promocodeFromURL));
    }
    if (promocodeFromURL) {
      history.push(`${routes.productLandingPage.url}${insuranceType}`);
    }
  };

  const getNextPageIfAgent = (insuranceType: string, lookupPage: string) =>
    isAgent() ? `${routes.getGenericQuotePage.url}${insuranceType}` : lookupPage;

  const nextPage = {
    [TRAVEL_INSURANCE_TYPE]: getNextPageIfAgent(
      TRAVEL_INSURANCE_TYPE,
      routes.getTravelDestination.url,
    ),
    [DEVICE_INSURANCE_TYPE]: getNextPageIfAgent(
      DEVICE_INSURANCE_TYPE,
      routes.deviceLookup.url,
    ),
    [VAN_INSURANCE_TYPE]: getNextPageIfAgent(
      VAN_INSURANCE_TYPE,
      routes.getVanRegPage.url,
    ),
    '': '',
  };

  const getPhoneNumbers = {
    home: homeInsurancePhoneNumber,
    car: motorInsurancePhoneNumber,
    travel: travelInsurancePhoneNumber,
    device: deviceInsurancePhoneNumber,
    van: vanInsuranceNumber
  };

  const renderDeviceMessage = () => {
    if (insuranceType === DEVICE_INSURANCE_TYPE) {
      return (
        <div className={`${className}__deviceMessageContainer`}>
          The device being insured must be less than 12 months old and purchased in the Republic of Ireland
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getButton = () => {
    if (isLoggedIn) {
      return <div className={`${className}__buttonSpacing`}>
        <Link to={`${routes.viewMultiplePolicies.url}${insuranceType}`}>
          <Button
            secondary
            fluid
            disabled={!hasPurchasedInsurance}
            data-ga
            id={`ProductPage__${insuranceType}ManageMyInsurance`}
            data-testid={`ProductPage__${insuranceType}ManageMyInsurance`}
          >
            MANAGE MY INSURANCE
      </Button>
        </Link>
      </div>;
    } else {
      return <div className={`${className}__buttonSpacing`}>
        <Button
          secondary
          fluid
          data-ga
          onClick={handleSubmit}
          id={`ProductPage__${insuranceType}Login`}
          data-testid={`ProductPage__${insuranceType}Login`}
        >
          LOGIN
          </Button>

      </div>;
    }
  };

  const getCustomClassName = () => {
    if (insuranceType === DEVICE_INSURANCE_TYPE) {
      return `${className}__deviceIcon`;
    } else {
      return `${className}__icon`;
    }
  };

  const genericQuotePage = () =>
    nextPage[insuranceType] || `${routes.getGenericQuotePage.url}${insuranceType}`;

  if (insuranceType) {
    return (
      <div className={className}>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${className}__content`}>
          <div className={`${className}__iconContainer`}>
            <div className={getCustomClassName()}>
              {iconsAndTitles[insuranceType].icon}
            </div>
          </div>
          <div className={`${className}__titleContainer`}>
            <div className={`${className}__titleText`}>
              <Text size="inherit">{iconsAndTitles[insuranceType].title}</Text>
            </div>
          </div>
          <div className={`${className}__mobileTitleText`}>
            <Text size="inherit">{iconsAndTitles[insuranceType].title}</Text>
          </div>
          <div className={`${className}__buttonContainer`}>

            {insuranceType === TRAVEL_INSURANCE_TYPE &&
              <div className={`${className}__covidMessageContainer`}>
                <AccordionCard status='none' id='covidNotificationCard' label="COVID-19">
                  <div className={`${className}__covidMessage`}>Warning: Important Information
                  About Covid-19
                </div>
                  <div className={`${className}__covidMessage`}>
                    Your Policy will not cover claims directly or
                    indirectly arising from, relating to or in any way
                    connected with Coronavirus (COVID-19) or any mutation or variation
                    of COVID-19 except for Medical Expenses and Repatriation
                    claims provided you are not travelling in, to or through
                    any area to which the Department of Foreign Affairs has
                    allocated a security status of “Avoid non-essential travel”
                    or “Do not travel” on its website <a href="https://www.dfa.ie/" target="_blank" rel="noopener noreferrer"><b>https://www.dfa.ie/</b></a> at
                    the time of travel. Chubb will not therefore cover claims relating
                    to any inability to travel, any decision not to travel or any changes
                    to travel plans as a result of COVID-19 or any mutation or variation of Covid-19.
                </div>
                  <div className={`${className}__covidMessage`}>
                    Warning: Important Information -
                    Travelling Abroad
                </div>
                  <div className={`${className}__covidMessage`}>
                    Your Policy will not provide cover if you are travelling to areas
                    where the Department of Foreign Affairs allocates a security status
                    of “Avoid non-essential travel” or “Do not travel”.
                    If you are not sure whether there is a travel warning for your destination,
                    please check their website <a href="https://www.dfa.ie/" target="_blank" rel="noopener noreferrer"><b>https://www.dfa.ie/</b></a>.
                </div>
                </AccordionCard>
              </div>
            }
            <div className={`${className}__buttonSpacing`}>
              <Link
                to={genericQuotePage}
                data-ga
                id={`ProductPage__${insuranceType}GetQuoteButton`}
                data-testid={`ProductPage__${insuranceType}GetQuoteButton`}
              >
                <Button onClick={gtmClickEvent} fluid={true}>GET A QUOTE</Button>
              </Link>
            </div>
            {getButton()}
            {renderDeviceMessage()}
            <div className={`${className}__spacing`} />
            <div className={`${className}__phoneIconContainer`}>
              <PhoneButton 
                color={primaryPurple} 
                label={`You can call us at ${getPhoneNumbers[insuranceType]}.`} 
                phoneNumber={getPhoneNumbers[insuranceType]} 
                isWhite
                onClick={() => {
                  fbqTrackEvent('Contact')
                }}>
                Need any help?
              </PhoneButton>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
export default withRouter(ProductLandingPage);
