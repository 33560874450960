

import '../PremiumBreakdown.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionCard } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { PremiumBreakdownRow } from '../PremiumBreakdownRow';


const noClaimDiscountScale = 'https://www.arachas.ie/van-NCD';

const VanPremiumBreakdown = (props) => {
  const className = 'c-PremiumBreakdown';

  const { payload, premiumDetails = [] } = props;
  //TODO fix up when have proper values coming back @amanda
  // eslint-disable-next-line no-unused-vars 
  let premium;
  // eslint-disable-next-line no-unused-vars
  let govLevyPrice;
  // eslint-disable-next-line no-unused-vars
  let noClaimsDiscountPrice;
  let premiumPenaltyPointsBreakDown = [];



  const setPrices = () => {
    const setPriceActions = {
      'Gov Levy': (price) => (govLevyPrice = price),
      'No Claims Discount': (price) => (noClaimsDiscountPrice = price),
    };


    const setRunningTotals = {
      'Premium': (total) => (premium = total),
    };

    if (premiumDetails.length > 0) {
      premiumDetails.forEach((premium) => {
        if (setPriceActions[premium.description]) {
          setPriceActions[premium.description](premium.amount);
        }
        if (setRunningTotals[premium.description]) {
          setRunningTotals[premium.description](premium.running_total);
        }
        if (premium.description.startsWith("PP:")) {
          premiumPenaltyPointsBreakDown.push(premium);
        }
      });
    }
  };

  setPrices();
  return (
    <AccordionCard
      id={`VanPremiumBreakdown__viewPremium`}
      label="View premium breakdown"
      status="none"
      labelStyle="black"
      childContainerClass={`${className}__noPaddingAccordion`}
      customLabelClass={`${className}__accordionLabel`}
    >
      <div className={`${className}`}>
        <div className={`${className}__section`}>
          <PremiumBreakdownRow
            label={`Premium`}
            price={payload.premium}
          />
          <PremiumBreakdownRow
            label={`Includes Government Levy`}
            price={payload.government_levy}
          />
          <PremiumBreakdownRow
            label={`Broker set up fee`}
            price={payload.service_fee}
          />
          <PremiumBreakdownRow
            label={`Total Premium`}
            price={payload.single_payment}
          />

          {premiumPenaltyPointsBreakDown.length > 0 ?
            <>
              <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
              <div className={`${className}__heading`}>The above premium includes the following loadings:</div>
              <PremiumBreakdownRow
                label={`Penalty Points Loading`}
              />
              {premiumPenaltyPointsBreakDown.map((penaltypoint, index) => {
                return (
                  <PremiumBreakdownRow
                    key={index}
                    label={penaltypoint.description.replace("PP: ", "")}
                    price={penaltypoint.amount}
                  />
                );
              })}
            </> :
            <></>}
          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
          <div className={`${className}__heading`}>The above premium includes the following discounts:</div>
          <PremiumBreakdownRow
            label={`No Claims Discount`}
            price={payload.total_no_claims_discount}
          />
          <div className={`${className}__breakdownLinktext`}>
            <p>See the No Claims Discount Scale <a href={noClaimDiscountScale} rel="noopener noreferrer" target="_blank" >here</a>.</p>
          </div>
        </div>
      </div>
    </AccordionCard >
  );
};

VanPremiumBreakdown.propTypes = {
  payload: PropTypes.object,
  premiumDetails: PropTypes.object,
};


export default VanPremiumBreakdown;
