// @flow

import '../PremiumBreakdown.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionCard } from '@arachas/core/lib';
import React from 'react';

import { isAxaCarInsurance } from '../../../constants/insurers/carInsurerIDs';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import type { MotorQuoteType, MotorViewQuoteTypes } from '../../../types';
import { PremiumBreakdownRow } from '../PremiumBreakdownRow';


interface Props {
  premiumDetails: MotorQuoteType,
  values: MotorViewQuoteTypes,
  payload: any,
  schemeId: number
}

const noClaimDiscountScale = 'https://www.arachas.ie/car-no-claims-bonus-scale.pdf';

const MotorPremiumBreakdown = (props: Props) => {
  const className = 'c-PremiumBreakdown';

  const { payload, premiumDetails = [] } = props;
  let premium = 0;
  let govLevyPrice = 0;
  let noClaimsDiscountPrice = 0;
  let premiumPenaltyPointsBreakDown = [];


  const setPrices = () => {
    const setPriceActions = {
      'Gov Levy': (price: any) => (govLevyPrice = price),
      'No Claims Discount': (price: any) => (noClaimsDiscountPrice = price),
    };


    const setRunningTotals = {
      'Premium': (total: any) => (premium = total),
    };

    if (premiumDetails.length > 0) {
      premiumDetails.forEach((premium: any) => {
        if (setPriceActions[premium.description]) {
          setPriceActions[premium.description](premium.amount);
        }
        if (setRunningTotals[premium.description]) {
          setRunningTotals[premium.description](premium.running_total);
        }
        if (premium.description.startsWith("PP:")) {
          premiumPenaltyPointsBreakDown.push(premium);
        }
      });
    }
  };

  setPrices();
  return (
    <AccordionCard
      id={`MotorPremiumBreakdown__viewPremium`}
      label="View premium breakdown"
      status="none"
      labelStyle="black"
      childContainerClass={`${className}__noPaddingAccordion`}
      customLabelClass={`${className}__accordionLabel`}
    >
      <div className={`${className}`}>
        <div className={`${className}__section`}>
          <PremiumBreakdownRow
            label={`Premium`}
            price={premium}
          />
          <PremiumBreakdownRow
            label={`Government Levy`}
            price={govLevyPrice}
          />
          <PremiumBreakdownRow
            label={`Broker set up fee`}
            price={payload.service_fee}
          />
          <PremiumBreakdownRow
            label={`Total Premium`}
            price={payload.total_amount}
          />

          {premiumPenaltyPointsBreakDown.length > 0 ?
            <>
              <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
              <div className={`${className}__heading`}>The above premium includes the following loadings:</div>
              <PremiumBreakdownRow
                label={`Penalty Points Loading`}
              />
              {premiumPenaltyPointsBreakDown.map((penaltypoint: any, index: number) => {
                return (
                  <PremiumBreakdownRow
                    key={index}
                    label={penaltypoint.description.replace("PP: ", "")}
                    price={penaltypoint.amount}
                  />
                );
              })}
            </> :
            <></>}
          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
          {!isAxaCarInsurance(parseInt(props.schemeId)) && (
            <React.Fragment>
              <div className={`${className}__heading`}>The above premium includes the following discounts:</div>
              <PremiumBreakdownRow
                label={`No Claims Discount`}
                price={noClaimsDiscountPrice}
              />
            </React.Fragment>
          )}

          <div className={`${className}__breakdownLinktext`}>
            <p>See the No Claims Discount Scale <a href={noClaimDiscountScale} rel="noopener noreferrer" target="_blank" >here</a>.</p>
          </div>
        </div>
      </div>
    </AccordionCard >
  );
};

export default MotorPremiumBreakdown;
