/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */
// TODO: refactor this
/* eslint-disable complexity */

import './GetGenericQuotePage.scss';

import { AnimatedSpinner } from '@arachas/core/lib';
import React, { useContext, useEffect, useState } from 'react';

import { CognitoContext } from '../../CognitoUtils';
import {
  CAR_INSURANCE_TYPE, DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import {
  getAccessToken,
  getItemFromSessionStorage,
  isAgent,
  makeRequest,
} from '../../helpers';
import { routes } from '../../routes';
import { getLatestUnpurchasedDeviceQuote } from '../../services/device/latestDeviceQuoteService';
import { getLatestUnpurchasedHomeQuote } from '../../services/latestHomeQuoteService';
import { getLatestUnpurchasedMotorQuote } from '../../services/motor/latestMotorQuoteService';
import { getLatestUnpurchasedTravelQuote } from '../../services/travel/latestTravelQuoteService';
import { getTravelResources } from '../../services/travel/travelResourcesServices';
import { getLatestUnpurchasedVanQuote } from '../../services/van/latestVanQuoteService';
import type {
  LatestQuoteType,
  RouterPropsType,
} from '../../types';
import type { TravelResourcesType } from '../../types/travel/YourTravelTypes';
import GetGenericQuotePage from './GetGenericQuotePage';
import resourcesForJourney from './resourcesForJourney';

const basePath = process.env.REACT_APP_API_ENDPOINT || '/';

const GetGenericQuotePageWrapper = (props: RouterPropsType) => {
  const className = 'c-GetGenericQuotePageForm';
  const [latestQuote, setLatestQuote] = useState(null);
  const [resources, setResources] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [cognitoUserData, setCognitoUserData] = useState({});
  const [travelResources, setTravelResources] = useState(null);
  const { cognitoUser } = useContext(CognitoContext);
  const isAnAgent = isAgent();

  const { match = {}, history } = props;

  const insuranceType =
    match.params && match.params.type ? match.params.type : '';

  const clientEmail =
    match.params && match.params.email ? match.params.email : '';

  //@amanda complexity errror here
  // eslint-disable-next-line complexity
  const getLatestUnpurchasedQuote = (email: any) => {
    const accessToken = getAccessToken(cognitoUser);
    const getLatestQuoteFunction = getLatestQuoteFunctionForInsuranceType();

    if (
      getLatestQuoteFunction &&
      accessToken &&
      (!isAgent() || (isAgent() && email))
    ) {
      return getLatestQuoteFunction(accessToken, props, email).then(
        (latestQuote: LatestQuoteType) => {
          if (Object.keys(latestQuote).length > 0) {
            setLatestQuote(latestQuote);
          } else {
            setLatestQuote({});
          }
        }
      );
    }
    // If no getLatestQuoteFunction found just set Latest Quote as an empty object
    setLatestQuote({});
  };

  const getLatestQuoteFunctionForInsuranceType = () => {
    switch (insuranceType) {
      case HOME_INSURANCE_TYPE:
        return getLatestUnpurchasedHomeQuote;
      case CAR_INSURANCE_TYPE:
        return getLatestUnpurchasedMotorQuote;
      case TRAVEL_INSURANCE_TYPE:
        return getLatestUnpurchasedTravelQuote;
      case DEVICE_INSURANCE_TYPE:
        return getLatestUnpurchasedDeviceQuote;
      case VAN_INSURANCE_TYPE:
        return getLatestUnpurchasedVanQuote;
    }
  };

  useEffect(() => {
    if (!latestQuote) {
      getLatestUnpurchasedQuote(clientEmail);
    }
  }, [latestQuote]);

  useEffect(() => {
    if (!resources) {
      let authToken = undefined;
      if (isAnAgent) {
        authToken = getItemFromSessionStorage(getAccessToken(cognitoUser));
      }
      const resourcesList = resourcesForJourney[insuranceType] || [];
      if (resourcesList.length > 0) {
        const promisesArray = resourcesList.map((requiredResource: any) =>
          makeRequest({
            url: `${basePath}/${requiredResource.service}/resources/${requiredResource.group
              }`,
            authToken: authToken,
          })
        );
        Promise.all(promisesArray).then((responses: any) => {
          const resourcesResults = responses.reduce(
            (reducer: any, value: any, index: any) => {
              reducer[resourcesList[index].group] = value;
              return reducer;
            },
            {}
          );
          setResources(resourcesResults);
        });
      } else setResources({});
    }
  }, [resources]);

  //travelMediaQuote
  useEffect(() => {
    if (insuranceType === TRAVEL_INSURANCE_TYPE) {
      getTravelResources(props).then((res: TravelResourcesType) => {
        res.trip_types ? setTravelResources(res) :
          /* If the trip types haven't been loaded correctly we can't proceed with
             the journey so redirect the user to the error page */
          history.push(`${routes.genericErrorPage.url}${TRAVEL_INSURANCE_TYPE}`);
      });


    }
  }, []);

  const isLoadingResources = () => {
    return latestQuote && resources && (insuranceType !== TRAVEL_INSURANCE_TYPE || travelResources);
  };

  return isLoadingResources() ? (
    <GetGenericQuotePage
      history={history}
      insuranceType={insuranceType}
      latestQuote={latestQuote}
      cognitoUserData={cognitoUserData}
      resources={resources}
      travelResources={travelResources}
    />
  ) : (
    <div className={`${className}__spinnerContainer`}>
      <AnimatedSpinner />
    </div>
  );
};

export default GetGenericQuotePageWrapper;
