/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import idx from 'idx';

import { getItemFromSessionStorage } from '../../helpers';
import { makeRequest } from '../../helpers';
import { rawToIrishFormat } from '../../helpers/DateHelper';

const getPayloadForAgent = email => (email ? { payload: { email } } : {});

export const getLatestVanQuote = async (
  cognitoAccessTokenKey,
  props = {},
  email = ''
) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const payload = getPayloadForAgent(email);
  return await makeRequest({
    method: email ? 'POST' : 'GET',
    url: `${basePath}/van/van_quotes/latest`,
    authToken: authToken,
    history: props.history,
    ...payload,
  });
};

export const hasPurchasedVanPolicy = async (cognitoAccessTokenKey, props) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return false because the call is going to fail
    return false;
  }
  return getLatestVanQuote(cognitoAccessTokenKey, props).then(response => {
    if (response.quote) {
      const data = formatResponse(response.quote);
      return !!data.policy_reference;
    }
    return false;
  });
};

export const getLatestUnpurchasedVanQuote = async (
  cognitoAccessTokenKey,
  props,
  email
) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return an object that specifies no token was passed
    return { hasNoAccessToken: true };
  }
  return getLatestVanQuote(cognitoAccessTokenKey, props, email).then(
    response => {
      if (response.quote) {
        const data = formatResponse(response.quote);
        return !data.policy_reference ? data : {};
      }
      return {};
    }
  );
};

const getAdditionalDrivers = (additionalDrivers = []) => {
  return additionalDrivers.map(driver => {
    return {
      title: driver.title,
      firstName: driver.first_name,
      lastName: driver.last_name,
      dob: rawToIrishFormat(driver.date_of_birth),
      relationshipToProposer: driver.relationship_to_proposer,
      driversLicenseType: driver.licence.type,
      licenceCountry: driver.licence.country,
      traderOccupation: driver.occupation,
      yearsHeld: driver.licence.years,
      additionalDriverPenaltyPointOffenceCount: 0,
      additionalDriverPenaltyPointOffences: [],
    };
  });
};


const getVanDetails = vanDetails => {
  if (vanDetails) {
    return {
      vanReg: vanDetails.registration,
      vanMake: vanDetails.make,
      vanType: vanDetails.body_type,
      vanModel: vanDetails.model,
      vanYear: vanDetails.year,
      vanWeight: vanDetails.payload_weight,
      vanDescription: vanDetails.description,

      carPurchased: vanDetails.years_owned_vehicle,
      vehicleValue: vanDetails.value.toString(),
      carUsedForWork: vanDetails.use,
    };
  }
  return {};
};

const getNCBDetails = ncb => {
  if (ncb) {
    return {
      ncdYears: ncb.years,
      ncdInsurer: ncb.insurer,
      ncdProctection: ncb.full_ncd_required,
      privateCar: ncb.other_vehicle_with_ncd,
    };
  }
  return {};
};

const getNoClaimsYears = (noClaimsYears = 0) => {
  if (noClaimsYears > 0) {
    return {
      noClaimsBonus: noClaimsYears,
    };
  }
  return {};
};

const getVanClaimsDetails = claims => {
  if (claims) {
    return {
      claimsCount: claims.length,
      motorClaims: claims.map(claim => {
        return {
          claim_type: claim.type,
          claim_status: claim.settled,
          claim_amount: claim.amount,
          claim_date: claim.date
        };
      }),
    };
  }
  return {};
};

const getDateOfBirth = date_of_birth => {
  return date_of_birth
    ? rawToIrishFormat(date_of_birth, 'YYYY-MM-DD')
    : '';
};

// TODO fix complexity error
// eslint-disable-next-line complexity
function formatResponse(data = {}) {
  const { quote_reference, policy_reference, promotion_code } = data;
  const {
    title,
    first_name,
    last_name,
    date_of_birth,
    address,
    phone,
    email,
    licence,
    ncb_year_on_other_policy,
    claims,
    ncd,
    // years_resident
  } = data.main_driver;

  const { vehicle_details } = data.cover_details || {};

  const additionalDrivers = data.additional_drivers || [];
  return {
    quote_reference,
    policy_reference,
    values: {
      proposerType: idx(data.main_driver, _ => _.proposer_type) || '',
      tradingAs: idx(data.main_driver, _ => _.trading_as) || '',
      companyName: idx(data.main_driver, _ => _.company_name) || '',
      title: title,
      firstName: first_name,
      lastName: last_name,
      addressLine1: address.address_line1,
      addressLine2: address.address_line2,
      addressLine3: address.address_line3,
      addressCounty: address.county ? address.county : address.address_line4,
      addressTown: address.town,
      addressEircode: address.post_code,
      phoneNo: phone,
      email: email,
      dob: getDateOfBirth(date_of_birth),
      driversLicenseType: idx(licence, _ => _.type),
      licenceCountry: idx(licence, _ => _.country),
      traderOccupation: idx(data.main_driver, _ => _.occupation) || '',
      vatRegistered: idx(data.main_driver, _ => _.vat_registered),
      // yearsOfResidency: years_resident,
      yearsHeld: idx(licence, _ => _.years),
      ...getVanDetails(vehicle_details),
      signage: idx(data, _ => _.cover_details.vehicle_details.has_signage),
      annualKm: idx(data, _ => _.cover_details.vehicle_details.km_per_year),
      ...getNCBDetails(ncd),
      ...getNoClaimsYears(ncb_year_on_other_policy),
      coverType: idx(data, _ => _.cover_details.type) || '',
      ...getVanClaimsDetails(claims),
      whoIsDrivingYourVan: idx(data, _ => _.cover_details.who_driving) || '',
      additionalDrivers: getAdditionalDrivers(additionalDrivers),
      hasAdditionalDrivers: additionalDrivers.length > 0,
      promoCode: promotion_code,
      areaUse: idx(data, _ => _.cover_details.vehicle_details.area_use),
      subAreaUse: idx(data, _ => _.cover_details.vehicle_details.sub_area_use)
    },
  };
}
