import 'react-app-polyfill/ie11';
import './index.scss';
import './amplify.config';
import 'react-app-polyfill/ie11';
import './utils/polyfills';
import './scripts/googleAnalyticsHandlers';

import { AnimatedSpinner } from '@arachas/core/lib';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { oneTrustCallScript,oneTrustEmptyScript } from '../src/scripts/oneTrustElements';
import { BOIRoutes } from './BOIRoutes';
import CognitoProvider from './CognitoUtils';
import ScrollToTop from './components/ScrollToTop';
import { CONSENT_KEY, LOCATION_HISTORY_STORAGE } from './constants/sessionStorage';
import { MAINTAINENCE_URL } from './constants/urlConstants';
import { getItemFromSessionStorage, isAgent, removeFromSessionStorage } from './helpers';
import { UseStatus } from './hooks/UseStatus/UseStatus';
import { facebookTrigger, fbqTrackEvent } from './scripts/facebookPixelEvents';
import * as serviceWorker from './serviceWorker';
import { UserContextProvider } from './UserContext';
import UserProfile from './UserProfile';

if (process.env.NODE_ENV === 'development') {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

const classes = 'center-aligned c-App';

const onUnloadApp = () => {
  const pageIsReloading = window.performance && performance.navigation.type === performance.navigation.TYPE_RELOAD;
  if (!pageIsReloading) {
    /* If the page is not reloading remove the users navigation history */
    removeFromSessionStorage(LOCATION_HISTORY_STORAGE);
  }
};
window.onbeforeunload = onUnloadApp;

if (window.navigator.userAgent.indexOf("MSIE") >= 0 || (!!window.MSInputMethodContext && !!document.documentMode)) {
  document.getElementById("internetExplorerWarning").style.display = "block";
} else {

  // eslint-disable-next-line flowtype/no-types-missing-file-annotation, complexity
  const ConnectedApp = () => { 
    const { checkStatus } = UseStatus()
    const [maintainenceMode, setMaintainenceMode] = useState({
      loading: true,
      mode: false
    })

    useEffect(() => {
      if(maintainenceMode.mode) {
        window.history.pushState({id: 1}, null, `${MAINTAINENCE_URL}`)
        window.location.href = `${process.env.PUBLIC_URL}${MAINTAINENCE_URL}`;
      }
    }, [maintainenceMode.mode])

    // eslint-disable-next-line complexity
    useEffect(() => {
      // eslint-disable-next-line complexity
      checkStatus().then((response) => {
        if(response.error || response.maintenance_mode) {
          setMaintainenceMode({
            loading: false,
            mode: true
          })
        } else {
          setMaintainenceMode({
            loading: false,
            mode: response.maintenance_mode || false
          })
          const consentData = getItemFromSessionStorage(CONSENT_KEY)
          if (!isAgent() && (consentData === null || consentData === undefined)) {
            document.head.prepend(oneTrustEmptyScript());
            document.head.prepend(oneTrustCallScript());
          } else {
            if(!isAgent()) {
              window.allowConsent = JSON.parse(consentData.toLowerCase())
            }
          }
        }
    }).catch(() => {
      setMaintainenceMode({
        loading: false,
        mode: true
      })
    })

    }, [])

    useEffect(() => {
      window.addEventListener('click', (event) => { 
        if(window.allowConsent === undefined) {
          facebookTrigger(event) 
          fbqTrackEvent('PageView')
        }
      });
    }, [window.OneTrust, window.allowConsent])


    if(maintainenceMode.loading) {
      return (
        <div className={classes}>
          <AnimatedSpinner/>
        </div>
      )
    } else if(!maintainenceMode.loading && !maintainenceMode.mode){
      return (<>
      <UserContextProvider>
        <BrowserRouter basename={''}>
          <ScrollToTop />
          <CognitoProvider>
            {(cognitoUser) =>
              <UserProfile cognitoUser={cognitoUser}>
                <BOIRoutes />
              </UserProfile>
            }
          </CognitoProvider>
          </BrowserRouter>
      </UserContextProvider></>);
    } else if(maintainenceMode.mode && !maintainenceMode.loading) {
      return <></>
    }
  };

  ReactDOM.render(<ConnectedApp />, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
}