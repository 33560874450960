/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import './CarPayment.scss';

import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CardPayment from '../../../components/Payment/CardPayment';
import QuoteDisplay from '../../../components/QuoteDisplay';
import {
  GET_QUOTE_RESPONSE_STORAGE,
  SINGLE_SCHEMA_STORAGE
} from '../../../constants';
import { CAR_INSURANCE_TYPE } from '../../../constants/insuranceTypeConstants';
import { TOTAL } from '../../../constants/paymentTypes';
import { getCurrentDateAndTime, getObjectFromSessionStorage, isAgent } from '../../../helpers';
import { routes } from '../../../routes';
import { fbqTrackEvent } from '../../../scripts/facebookPixelEvents';
import { pay } from '../../../services/paysafeService';
import GoCardlessDirectDebit from '../GoCardlessDirectDebit';
import { get3DSObject } from '../ThreeDS';
import carPaymentSchema from './carPaymentSchema.js';
import { PAYMENT_FREQUENCY } from './values';

const paymentOptions = [
  { label: 'Monthly', value: PAYMENT_FREQUENCY.MONTHLY },
  { label: 'Single', value: PAYMENT_FREQUENCY.TOTAL },
];

const CarPaymentForm = (props) => {
  const {
    isValid,
    values,
    setFieldValue,
    insuranceType,
    history,
  } = props;

  const [cardBrand, setCardBrand] = useState('');
  const [selectedQuoteData, setSelectedQuoteData] = useState({
    scheme: 0,
    price: 0,
    quoteReference: '0',
    quoteType: '',
    monthlyPayment: 0,
    depositPayment: 0,
  });
  const [paysafeError, setPaysafeError] = useState('');

  const redirectToHome = () => {
    history.push({ pathname: routes.Wallet.url });
  };

  const readSelectedQuoteData = () => {
    let paymentData;
    const selectedSchema = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${SINGLE_SCHEMA_STORAGE}`);
    if (Object.keys(selectedSchema).length > 0) {
      paymentData = selectedSchema;
    }
    else {
      paymentData = getObjectFromSessionStorage(`${CAR_INSURANCE_TYPE}${GET_QUOTE_RESPONSE_STORAGE}`)[0];
    }

    if (!paymentData) redirectToHome();
    else {
      const quoteType = paymentData.selectedQuoteType;
      setSelectedQuoteData({
        scheme: paymentData.scheme,
        quoteReference: paymentData.quote_reference,
        quoteType,
        price: paymentData.premium.total_amount,
        monthlyPayment: paymentData.premium.direct_debit_recurring_payment,
        directDebitNumberOfInstallments: paymentData.premium.direct_debit_number_of_installments,
        depositPayment: paymentData.premium.direct_debit_deposit,
      });
    }
  };

  useEffect(() => {
    readSelectedQuoteData();
  }, []);


  useEffect(() => {
    setPaysafeError('');
  }, [values]);

  const buildPaymentsDetails = {
    'CARD_PAYMENT': (paymentToken) => {
      return {
        'payment_details': {
          'payment_id': paymentToken,
          'payment_type': 'CARD_PAYMENT',
          'purchaser': values.nameOnCard,
          'card_type': cardBrand,
          'quote_reference': selectedQuoteData.quoteReference,
          'payment_date': getCurrentDateAndTime(),
          'product_selected': selectedQuoteData.scheme,
          'collection_day': '',
          'installments_number': '0',
          'bank_identifier_code': '',
          'international_bank_account_number': '',
          'price': selectedQuoteData.price,
        },
      };
    },
    'DIRECT_DEBIT': () => {
      return {
        'payment_details': {
          'payment_type': 'DIRECT_DEBIT_PAYMENT',
          'purchaser': values.accountHolderName,
          'card_type': 'DD',
          'quote_reference': selectedQuoteData.quoteReference,
          'installments_number': '10',
          'payment_date': getCurrentDateAndTime(),
          'bank_identifier_code': values.bic,
          'international_bank_account_number': values.iban,
          'account_holder_name': values.accountHolderName,
          'collection_day': parseInt(values.collectionDate),
          'product_selected': selectedQuoteData.scheme,
          'price': selectedQuoteData.price,
        },
      };
    },
  };

  const goToPaymentsThankYouPage = (paymentDetails) => {
    props.history.push({
      pathname: `${routes.paymentThankYou.url}${insuranceType}`,
      state: {
        paymentDetails: paymentDetails,
        insuranceType: 'car',
      },
    });
  };

  const submitPayment = () => {
    fbqTrackEvent('InitiateCheckout');
    if (values.paymentFrequency === PAYMENT_FREQUENCY.TOTAL) {
      const successFn = (paymentToken) => {
        const paymentDetails = buildPaymentsDetails['CARD_PAYMENT'](paymentToken);
        goToPaymentsThankYouPage(paymentDetails);
      };
      const errorFn = (code, message) => setPaysafeError(`${message} (Code: ${code})`);
      const pay3DSObj = !isAgent() ? get3DSObject(getQuoteDisplayPrice(), values.nameOnCard) : false;
      pay(successFn, errorFn, CAR_INSURANCE_TYPE, pay3DSObj);
    } else {
      const paymentDetails = buildPaymentsDetails['DIRECT_DEBIT']();
      goToPaymentsThankYouPage(paymentDetails);
    }
  };

  const setCardValidity = (isValidCard) => {
    setFieldValue('cardIsValid', isValidCard);
  };

  const getQuoteDisplayPrice = () => selectedQuoteData.price;

  const getQuoteDisplayTitle = () => 'Total';

  const getCardPaymentFields = (paymentFrequency) => paymentFrequency && paymentFrequency === PAYMENT_FREQUENCY.TOTAL ? (
    <CardPayment
      setCardBrand={setCardBrand} {...props}
      insuranceType={CAR_INSURANCE_TYPE}
      paymentOptions={paymentOptions}
      setIsValid={setCardValidity}
      paysafeError={paysafeError}
    />
  ) : <></>;

  const formIsValid = () => values.paymentFrequency === PAYMENT_FREQUENCY.TOTAL ? isValid && values.cardIsValid : isValid;

  return (
    <Form>

      {getCardPaymentFields(values.paymentFrequency)}

      {values.paymentFrequency === PAYMENT_FREQUENCY.MONTHLY ?
        <GoCardlessDirectDebit {...props} paymentOptions={paymentOptions}
          selectedQuoteData={selectedQuoteData} /> : null}

      <QuoteDisplay
        sticky
        price={getQuoteDisplayPrice()}
        title={getQuoteDisplayTitle()}
        buttonType="Buy"
        disabled={!formIsValid()}
        onClickFunction={submitPayment}
        quoteReference={selectedQuoteData.quoteReference}
        paymentType={TOTAL}
      />
    </Form>
  );
};

const CarPayment = withFormik({
  mapPropsToValues() {
    return {
      paymentFrequency: PAYMENT_FREQUENCY.MONTHLY,
      bankRequireOneSignature: '',
      bankAccountYoursWillAcceptDirectDebit: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      county: '',
      bic: '',
      iban: '',
      collectionDate: '',
      accountHolderName: '',
      cardIsValid: false,
    };
  },
  handleSubmit(
    values,
    { setSubmitting },
  ) {
    setSubmitting(false);
  },
  validationSchema: carPaymentSchema,
  displayName: 'CarPaymentForm',
})(CarPaymentForm);

CarPaymentForm.propTypes = {
  isValid: PropTypes.bool,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  insuranceType: PropTypes.string,
  history: PropTypes.object,
};

export default CarPayment;
