/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

 // eslint-disable-next-line complexity
 export const isAgent = () => {
  if(process.env.REACT_APP_AGENT_ENABLED  !== undefined && process.env.REACT_APP_AGENT_ENABLED  !== null) {
    let isAnAgent = process.env.REACT_APP_AGENT_ENABLED;
    if(isAnAgent !== null && isAnAgent !== undefined) {
      const val = isAnAgent.toString() === 'TRUE' ? true : false;
      return val
    }
  }
};
