/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './WalletLandingPage.scss';
import '../../styles/commonPageStyles.scss';

import { ButtonCard, Text, TitleWithUnderLine } from '@arachas/core/lib';
import { colors, screenSize } from '@arachas/core/lib/styles';
import React, { useContext, useEffect, useState } from 'react';
import type { RouterHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { CognitoContext } from '../../CognitoUtils';
import InstallPrompt from '../../components/InstallPrompt/InstallPrompt';
import MyPolicies from '../../components/MyPolicies';
import Popup from '../../components/Popup';
import { commonPageStylesIdentifier } from '../../constants';
import { darkOrange } from '../../constants/colors';
import {
  CAR_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
} from '../../constants/insuranceTypeConstants';
import {
  removeAllJourneysSessionStorage,
  removeAllStoredPolicyNumbers,
} from '../../helpers';
import { isAgent } from '../../helpers';
import { routes } from '../../routes';
import type { UserProfileType } from '../../types/profile';
import UserContext from '../../UserContext';
import MessagesCloud from './MessagesCloud';

type WalletLandingPageProps = {
  userProfile: UserProfileType,
  history: RouterHistory;
};

const WalletLandingPage = (props: WalletLandingPageProps) => {
  const { userProfile, history } = props;
  const [, setIsDesktop] = useState(false);
  const [existingPolicies, setExistingPolicies] = useState({
    car: false,
    home: false,
    travel: false,
    van: false,
    business: false,
    life: false,
    pet: false,
    health: false,
    pensions: false,
    savings: false,
  });
  const [showJourneys, setShowJourneys] = useState(false);
  const [showRenewal] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const { userDispatch } = useContext(UserContext);
  const { cognitoUser } = useContext(CognitoContext);

  useEffect(() => {
    // check if its a desktop screen
    const desktop = window.innerWidth > screenSize.minDesktopWidth;
    setIsDesktop(desktop);
    setShowJourneys(desktop);

    if (cognitoUser !== null) {
      setShowLogin(false);
      if (cognitoUser.attributes && cognitoUser.attributes.given_name) {
        userDispatch({
          type: 'setFirstName',
          payload: { firstName: cognitoUser.attributes.given_name },
        });
      }
    }
    removeAllJourneysSessionStorage();
    removeAllStoredPolicyNumbers();
  }, [cognitoUser]);

  useEffect(() => {
    if (userProfile && userProfile.purchasedInsuranceTypes) {
      const purchasedInsuranceTypes = userProfile.purchasedInsuranceTypes;
      // call api to check what policies user has & update existingPolicies object
      setExistingPolicies({
        car: purchasedInsuranceTypes[CAR_INSURANCE_TYPE],
        home: purchasedInsuranceTypes[HOME_INSURANCE_TYPE],
        travel: isAgent()
          ? false
          : purchasedInsuranceTypes[TRAVEL_INSURANCE_TYPE],
        van: false,
        business: false,
        life: false,
        pet: false,
        health: false,
        pensions: false,
        savings: false,
      });
    }
  }, [userProfile]);


  const getButtonCardColour = (card: string): string => {
    return existingPolicies[card]
      ? colors.primaryColor
      : colors.lightPrimaryColor;
  };

  const getTitleText = () =>
    cognitoUser && cognitoUser.attributes && cognitoUser.attributes.given_name
      ? `Welcome, ${cognitoUser.attributes.given_name}!`
      : 'Welcome!';

  const className = 'c-WalletLandingPage';
  const hiddenJourneys = {
    pet: 'Pet',
    health: 'Health',
    pensions: 'Pensions',
    savings: 'Savings & Investments',
  };

  const renewalNotification = showRenewal ? (
    <Popup
      color={darkOrange}
      heading={() => (
        <Text align="left" type="quinary">
          Renewal time!
        </Text>
      )}
      icon="car"
    >
      <Text align="left">
        It&apos;s almost time to renew your car insurance.
      </Text>
    </Popup>
  ) : null;

  if (showJourneys) {
    const buttonCards = [];
    Object.keys(hiddenJourneys).forEach((key: string) =>
      buttonCards.push(
        <div className={`${className}__ButtonContainer`} key={key}>
          <Link
            to="/wallet"
            data-ga
            id={`WalletLandingPage__${key}WalletCardLink`}
            data-testid={`WalletLandingPage__${key}WalletCardLink`}
            className={`${className}__buttonCardLink--disabled`}
          >
            <ButtonCard
              icon={key}
              text={hiddenJourneys[key]}
              comingSoon
              color={getButtonCardColour(key)}
            />
          </Link>
        </div>,
      ),
    );
  }

  const renderTravelTile = () => (
    <div className={`${className}__ButtonContainer`}>
      <Link
        to={isAgent() ? '/wallet' : '/wallet/product/travel'}
        data-ga
        id="WalletLandingPage__travelButtonCard"
        data-testid="WalletLandingPage__travelButtonCard"
      >
        <ButtonCard
          icon="travel"
          text="Travel"
          comingSoon={isAgent()}
          color={getButtonCardColour('travel')}
        />
      </Link>
    </div>
  );

  const shouldRenderMyPolicies = () => {
    return !isAgent() && userProfile.policies && userProfile.policies.length > 0 &&
      <>
        <MyPolicies
          userProfile={userProfile}
          history={history}
        />
        <h1 className={`${className}__quotesTitle`}>
          Get More Quotes
        </h1>
      </>;
  };

  const render = () => {
    return (
      <div className={className}>
        <div className={`${className}__TopContainer`}>
          {renewalNotification}
          <div className={`${className}__WelcomeContainer`}>
            <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
              <div className={`${className}__WelcomeText`}>
                {isAgent() && (
                  <Link
                    to="/wallet/latest-quotes"
                    data-ga
                    id="WalletLandingPage__agentLatestQuotes"
                    data-testid="WalletLandingPage__agentLatestQuotes"
                  >
                    <div className={`${className}__WelcomeContainer__latestQuotes`}>
                      VIEW LATEST QUOTES
                    </div>
                  </Link>
                )}
                <Text size="inherit">{getTitleText()}</Text>
              </div>
            </div>

            <div className={`${commonPageStylesIdentifier}__showOnDesktopOnly`}>
              <div className={`${className}__pageTitle`}>
                {isAgent() && (
                  <Link
                    to="/wallet/latest-quotes"
                    data-ga
                    id="WalletLandingPage__agentLatestQuotes"
                    data-testid="WalletLandingPage__agentLatestQuotes"
                  >
                    <div className={`${className}__WelcomeContainer__latestQuotes`}>
                      View Latest Quotes
                    </div>
                  </Link>
                )}
                <TitleWithUnderLine>{getTitleText()}</TitleWithUnderLine>
              </div>
            </div>
            <MessagesCloud />
          </div>
        </div>
        <div>
          {shouldRenderMyPolicies()}
          <div className={`${className}__ButtonGridContainer`}>
            <div className={`${className}__ButtonGrid`}>
              <div className={`${className}__ButtonContainer`}>
                <Link
                  to="/wallet/product/car"
                  data-ga
                  id="WalletLandingPage__carButtonCard"
                  data-testid="WalletLandingPage__carButtonCard"
                >
                  <ButtonCard
                    icon="car"
                    text="Car"
                    color={getButtonCardColour('car')}
                  />
                </Link>
              </div>
              <div className={`${className}__ButtonContainer`}>

                <Link
                  to="/wallet/product/home"
                  data-ga
                  id="WalletLandingPage__homeButtonCard"
                  data-testid="WalletLandingPage__homeButtonCard"
                >
                  <ButtonCard
                    icon="home"
                    text="Home"
                    color={getButtonCardColour('home')}
                  />
                </Link>
              </div>
              {renderTravelTile()}
              <div className={`${className}__ButtonContainer ${className}--reducedIcon`}>
                <Link
                  to="/wallet/product/device"
                  data-ga
                  id="WalletLandingPage__deviceButton"
                  data-testid="WalletLandingPage__deviceButton"
                >
                  <ButtonCard
                    icon="device"
                    text="Device"
                    color={getButtonCardColour('device')}
                  />
                </Link>
              </div>
              <div className={`${className}__ButtonContainer`}>
                <Link
                  to="/wallet/product/van"
                  data-ga
                  id="WalletLandingPage__vanButtonCar"
                  data-testid="WalletLandingPage__vanButtonCard"
                >
                  <ButtonCard
                    icon="van"
                    text="Van"
                    color={getButtonCardColour('van')}
                  />
                </Link>
              </div>
              <div className={`${className}__ButtonContainer ${className}--reducedTextOnMobile`}>
                <Link
                  to="/wallet/product/personalAccident"
                  data-ga
                  id="WalletLandingPage__personalAccident"
                  data-testid="WalletLandingPage__personalAccident"
                  className={`${className}__buttonCardLink--disabled`}
                >
                  <ButtonCard
                    icon="accident"
                    text="Personal Accident"
                    comingSoon
                    color={getButtonCardColour('accident')}
                  />
                </Link>
              </div>
            </div>
          </div>
          {showLogin ? (
            <Link
              to={{ pathname: routes.loginPage.url }}
              className={`${className}__link`}
              data-ga
              id="homeLoginButtonLink"
              data-testid="homeLoginButtonLink"
            >
              MyArachas Login
            </Link>
          ) : null}
          <InstallPrompt />
        </div>
      </div>
    );
  };

  return render();
};

export default WalletLandingPage;
