/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import idx from 'idx';

import { coverTypeComprehensive } from '../../components/Forms/YourCarCoverForm/yourCarCoverFormInitialValues';
import { getItemFromSessionStorage } from '../../helpers';
import { makeRequest } from '../../helpers';
import { rawToIrishFormat } from '../../helpers/DateHelper';

const getPayloadForAgent = email => (email ? { payload: { email } } : {});

export const getLatestMotorQuote = async (
  cognitoAccessTokenKey,
  props = {},
  email = ''
) => {
  const authToken = getItemFromSessionStorage(cognitoAccessTokenKey) || '';
  const basePath = process.env.REACT_APP_API_ENDPOINT || '/';
  const payload = getPayloadForAgent(email);
  return await makeRequest({
    method: email ? 'POST' : 'GET',
    url: `${basePath}/motor/motor_quotes/latest`,
    authToken: authToken,
    history: props.history,
    ...payload,
  });
};

export const hasPurchasedMotorPolicy = async (cognitoAccessTokenKey, props) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return false because the call is going to fail
    return false;
  }
  return getLatestMotorQuote(cognitoAccessTokenKey, props).then(response => {
    if (response.quote) {
      const data = formatResponse(response.quote);
      return !!data.policy_reference;
    }
    return false;
  });
};

export const getLatestUnpurchasedMotorQuote = async (
  cognitoAccessTokenKey,
  props,
  email
) => {
  if (!cognitoAccessTokenKey) {
    // If cognitoAccessTokenKey is undefined just return an object that specifies no token was passed
    return { hasNoAccessToken: true };
  }
  return getLatestMotorQuote(cognitoAccessTokenKey, props, email).then(
    response => {
      if (response.quote) {
        const data = formatResponse(response.quote);
        return !data.policy_reference ? data : {};
      }
      return {};
    }
  );
};

const getPrimaryAddresss = primary_address => {
  return primary_address
    ? {
      addressLine1: primary_address.address_line1,
      addressLine2: primary_address.address_line2,
      addressLine3: primary_address.address_line3,
      addressCounty: primary_address.county,
      addressTown: primary_address.town,
      addressEircode: primary_address.eircode,
    }
    : {};
};

const getPostalAddress = (primaryAddress, postalAddress = {}) => {
  if (primaryAddress && primaryAddress.post_code !== postalAddress.post_code) {
    return {
      isThisYourPostalAddress: false,
      postalAddressLine1: postalAddress.address_line1,
      postalAddressLine2: postalAddress.address_line2,
      postalAddressLine3: postalAddress.address_line3,
      postalAddressTown: postalAddress.town,
      postalAddressCounty: postalAddress.county,
      postalAddressEircode: postalAddress.eircode,
    };
  }
  return {
    isThisYourPostalAddress: true,
    postalAddressLine1: '',
    postalAddressLine2: '',
    postalAddressLine3: '',
    postalAddressTown: '',
    postalAddressCounty: '',
    postalAddressEircode: '',
  };
};

const getAdditionalDrivers = (additionalDrivers = []) => {
  return additionalDrivers.map(driver => {
    return {
      title: driver.title,
      firstName: driver.first_name,
      lastName: driver.last_name,
      dob: rawToIrishFormat(driver.date_of_birth),
      relationshipToProposer: driver.relationship_to_proposer,
      driversLicenseType: driver.licence.type,
      employmentStatus: driver.employment_status,
      mainOccupation: driver.occupation,
      yearsHeld: driver.licence.years,
      additionalDriverPenaltyPointOffenceCount: 0,
      additionalDriverPenaltyPointOffences: [],
      useOfOtherCar: driver.full_time_use_of_other_car
    };
  });
};

const getCarDetails = carDetails => {
  if (carDetails) {
    return {
      carReg: carDetails.registration,
      carModel: carDetails.model,
      carMake: carDetails.make,
      carYear: carDetails.year,
      carVersion: carDetails.version,
      carFuelType: carDetails.fuel,
      carEngineSize: carDetails.cc,

      carPurchased: carDetails.years_owned_vehicle,
      vehicleValue: carDetails.value,
      carUsedForWork: carDetails.use,
    };
  }
  return {};
};

const getNCBDetails = ncb => {
  if (ncb) {
    return {
      ncbCountry: ncb.country,
      noClaimsBonus: ncb.years,
      drivingExpType: ncb.type,
    };
  }
  return {};
};

const getNoClaimsYears = (noClaimsYears = 0) => {
  if (noClaimsYears > 0) {
    return {
      noClaimsBonus: noClaimsYears,
    };
  }
  return {};
};

const getClaimsDetails = claims => {
  if (claims) {
    return {
      motorClaims: claims,
      claimsCount: claims.length,
    };
  }
  return {};
};

const getDateOfBirth = date_of_birth => {
  return date_of_birth
    ? rawToIrishFormat(date_of_birth, 'YYYY-MM-DD')
    : '';
};

// TODO fix complexity error
// eslint-disable-next-line complexity
function formatResponse(data = {}) {
  const { quote_reference, policy_reference, promotion_code } = data;

  const {
    title,
    first_name,
    last_name,
    date_of_birth,
    primary_address,
    mobile_phone,
    email,
    employment_status,
    occupation,
    licence,
    ncb_year_on_other_policy,
    claims,
    ncb,
  } = data.main_driver;

  const { risk_address, vehicle_details } = data.cover_details || {};

  const additionalDrivers = data.additional_drivers || [];
  return {
    quote_reference,
    policy_reference,
    values: {
      title: title,
      firstName: first_name,
      lastName: last_name,
      ...getPrimaryAddresss(primary_address || {}),
      ...getPostalAddress(primary_address, risk_address),
      phoneNo: mobile_phone,
      email: email,
      dob: getDateOfBirth(date_of_birth),
      driversLicenceNumber: idx(licence, _ => _.number),
      employmentStatus: employment_status,
      mainOccupation: occupation,
      driversLicenseType: idx(licence, _ => _.type),
      yearsHeld: idx(licence, _ => _.years),
      ...getCarDetails(vehicle_details),
      ...getNCBDetails(ncb),
      ...getNoClaimsYears(ncb_year_on_other_policy),
      coverType: idx(data, _ => _.cover_details.type) || coverTypeComprehensive,
      ...getClaimsDetails(claims),
      whoIsDrivingYourCar: idx(data, _ => _.cover_details.who_driving) || { alias: '' },
      additionalDrivers: getAdditionalDrivers(additionalDrivers),
      hasAdditionalDrivers: additionalDrivers.length > 0,
      promoCode: promotion_code,
    },
  };
}
